<template>
  <div class="merlends">
    <div class="headstyle">
      <div class="caedl" style="width: 40%">
        <div class="carsed">
          <div>¥{{ itemData.totalPayableAmount }}</div>
          <div>销售合计</div>
        </div>
        <div class="carsed">
          <div>¥{{ itemData.totalRefundAmount }}</div>
          <div>退款合计</div>
        </div>
      </div>
      <div class="caedl" style="flex: 1; margin-left: 20px">
        <div class="carsed">
          <div>{{ itemData.normalCount }}</div>
          <div>正常</div>
        </div>
        <div class="carsed">
          <div>{{ itemData.shotCount }}</div>
          <div>短款</div>
        </div>
        <div class="carsed">
          <div>{{ itemData.longCount }}</div>
          <div>长款</div>
        </div>
        <div class="carsed">
          <div>{{ itemData.notEqualCount }}</div>
          <div>不一致</div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <el-form
          ref="queryForm"
          :model="queryParams"
          :inline="true"
          @keyup.enter.native="search"
        >
          <el-form-item label="支付时间" prop="listTime">
            <el-date-picker
              v-model="queryParams.listTime"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            />
          </el-form-item>
          <el-form-item label="订单号：">
            <el-input
              v-model="queryParams.orderId"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="支付订单号：">
            <el-input
              v-model="queryParams.paymentSerialNumber"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="平台批次号：">
            <el-input
              v-model="queryParams.orderGroupId"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="供应商：">
            <el-input
              v-model="queryParams.supplier"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="资方渠道：">
            <el-input
              v-model="queryParams.signChannel"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="支付对账结果：">
            <el-select
              clearable
              v-model="queryParams.compareResult"
              placeholder="请选择"
            >
              <el-option label="长款" :value="1"></el-option>
              <el-option label="短款" :value="2"></el-option>
              <el-option label="金额不一致" :value="3"></el-option>
              <el-option label="正常" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="退款对账结果：">
            <el-select
              clearable
              v-model="queryParams.refundCompareResult"
              placeholder="请选择"
            >
              <el-option label="长款" :value="1"></el-option>
              <el-option label="短款" :value="2"></el-option>
              <el-option label="金额不一致" :value="3"></el-option>
              <el-option label="正常" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否交易前还款：">
            <el-select
              v-model="queryParams.settleStatus"
              placeholder="请选择"
              clearable
            >
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search" class="marle_10"
              >查 询</el-button
            >
            <el-button type="primary" @click="resets" class="marle_10"
              >重 置</el-button
            >
            <el-button type="primary" @click="downLoad" class="marle_10"
              >导 出</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格数据 -->
      <div class="tableData">
        <el-table
          border
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F2F3F5' }"
        >
          <el-table-column
            align="center"
            label="序号"
            type="index"
            :index="indexMethod"
            width="70"
          />
          <el-table-column
            align="center"
            label="订单号"
            prop="orderId"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.orderId || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="主订单号"
            prop="mainOrderId"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.mainOrderId || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="平台批次号"
            prop="orderGroupId"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.orderGroupId || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="供应商"
            prop="supplier"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.supplier || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="平台销售金额"
            prop="payableAmount"
            width="120"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.payableAmount || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="平台退款金额"
            prop="orderRefundAmount"
            width="120"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.orderRefundAmount">
                <span>-</span>
                <span v-if="Number(scope.row.orderRefundAmount)">
                  {{ scope.row.orderRefundAmount }}
                </span>
              </div>
              <div v-else>—</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="平台支付时间"
            prop="payTime"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.payTime || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="平台订单状态"
            prop="orderStatus"
            width="120"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.orderStatus || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="资方渠道" prop="signChannel">
            <template slot-scope="scope">
              <div>{{ scope.row.signChannel || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="支付订单号"
            prop="paymentSerialNumber"
            width="180"
          />
          <el-table-column
            align="center"
            label="盈众支付金额"
            prop="tradeAmount"
            width="120"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.tradeAmount || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="盈众退款金额"
            prop="factoringRefundAmount"
            width="120"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.factoringRefundAmount">
                <span>-</span>
                <span v-if="Number(scope.row.factoringRefundAmount)">
                  {{ scope.row.factoringRefundAmount }}
                </span>
              </div>
              <div v-else>—</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否出账" prop="isAlready">
            <template slot-scope="scope">
              <div>{{ scope.row.isAlready || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="盈众支付时间"
            prop="orderCreatedTime"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.orderCreatedTime || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="支付对账结果"
            prop="compareResult"
            width="120"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.compareResult || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="退款对账结果"
            prop="refundCompareResult"
            width="120"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.refundCompareResult || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="对账时间"
            prop="updateTime"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.updateTime || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="是否交易前还款"
            prop="settleStatus"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.settleStatus || "-" }}</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.perPage"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  billList, //白条对账单列表
} from "@/api/apiAll/phpUrl.js";
import { downLoadZip } from "@/utils/zipdownload";
export default {
  name: "",
  data() {
    return {
      loading: false, //数据加载动画
      queryParams: {
        page: 1,
        perPage: 10,
        listTime: [], //支付时间
        paymentStartTime: "", //支付开始时间
        paymentEndTime: "", //支付结束时间
        orderId: "", //订单号
        paymentSerialNumber: "", //支付订单号
        orderGroupId: "", //平台批次号
        supplier: "", //供应商
        signChannel: "", //资方渠道
        compareResult: "", //支付对账结果
        refundCompareResult: "", //退款对账结果
        settleStatus: "", //是否交易前还款
        opt: "list",
      },
      total: 0,
      tableData: [],
      itemData: {
        totalPayableAmount: "", //销售合计
        totalRefundAmount: "", //退款合计
        normalCount: "", //正常
        shotCount: "", //短款
        longCount: "", //长款
        notEqualCount: "", //不一致
      },
    };
  },
  created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getItem();
  },
  methods: {
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    getItem() {
      this.getList();
      this.getData();
    },
    getData() {
      var data = { ...this.queryParams };
      data.opt = "statistics";
      billList(data).then((res) => {
        Object.assign(this.itemData, this.$options.data().itemData);
        Object.assign(this.itemData, res.data);
      });
    },
    //** 列表数据查询 */
    getList() {
      if (this.queryParams.listTime && this.queryParams.listTime.length) {
        this.queryParams.paymentStartTime = this.queryParams.listTime[0];
        this.queryParams.paymentEndTime = this.queryParams.listTime[1];
      } else {
        this.queryParams.paymentStartTime = "";
        this.queryParams.paymentEndTime = "";
      }
      this.setquery();
      this.loading = true;
      billList(this.queryParams).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
      this.setquery();
    },
    /** 查询 */
    search() {
      this.queryParams.page = 1;
      this.getItem();
    },
    /** 重置 */
    resets() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getItem();
    },
    /** 导出 */
    downLoad() {
      var data = { ...this.queryParams };
      delete data.listTime;
      downLoadZip(
        `/factoring/billExport`,
        "xlsx",
        "白条对账单列表",
        data,
        "php",
        "get"
      );
    },
  },
};
</script>
<style lang='scss' scoped>
.headstyle {
  margin: 20px;
  display: flex;
  .caedl {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px 0px;
    .carsed {
      flex: 1;
      text-align: center;
      line-height: 30px;
      font-weight: bold;
    }
  }
}
</style>